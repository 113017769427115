import React, { useEffect, useRef } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import MainLogo from "../svgs/white-lion-logo.svg"
import MainMenu from "../components/main-menu"

function getOffset(el) {
  const rect = el.getBoundingClientRect()
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
  }
}

const Header = ({ toggleMenu, globalStatus, isOpen }) => {
  const navBar = useRef(null)

  const listenToScroll = () => {
    const topOfElemA = getOffset(navBar.current).top

    if (topOfElemA === 0) {
      navBar.current.style.position = "fixed"
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll)
    return () => window.removeEventListener("scroll", listenToScroll)
  }, [])

  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.core.globals("ScrollTrigger", ScrollTrigger)
    }

    // sticky header
    let tlheader = gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: "#sticky_vTwo",
        id: "sticky_trigger",
        start: "10px top",
        end: "10px top",
        toggleActions: "play play reverse reverse",
      },
    })
    tlheader.to(".sticky-header", {
      duration: 0.3,
      borderTop: "0px solid #fff",
      padding: "10px 0",
      boxShadow: "0 5px 10px rgba(0,0,0,.12)",
    })
  }, [])

  return (
    <MainHeader
      className={`sticky-header ${isOpen ? "sticky" : ""}`}
      id="sticky_vTwo"
      ref={navBar}
    >
      <HeaderFlex>
        <Logo>
          <Link to="/">
            <MainLogo
              data-sal="slide-down"
              data-sal-duration="1000"
              data-sal-easing="ease"
              data-sal-delay="0"
            />
          </Link>
        </Logo>
        <MainMenu />
      </HeaderFlex>
      <Hamburger id="hamburger_icon">
        <button
          onClick={() => {
            toggleMenu()
          }}
          aria-label="Open Mobile Menu"
          className={isOpen ? "mobileOpen" : ""}
        >
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </button>
      </Hamburger>
    </MainHeader>
  )
}

const Hamburger = styled.div`
  visibility: hidden;
  display: none;
  position: absolute;
  top: 22px;
  right: 20px;
  z-index: 5001;
  width: 30px;
  button {
    width: 30px;
    height: 19px;
    position: relative;
    background: transparent;
    border: none;
    outline: 0;
    padding: 0;
    visibility: hidden;
    display: none;
    &:hover {
      cursor: pointer;
    }
    > div {
      display: block;
      position: absolute;
      height: 2px;
      width: 30px;
      left: 0;
      background-color: #db4140;
      opacity: 1;
      transform: rotate(0deg) translate(0);
      transition: all 0.15s ease-in-out;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 8px;
      }
      &:nth-child(3) {
        top: 8px;
      }
      &:nth-child(4) {
        top: 16px;
      }
    }
    &.mobileOpen {
      > div {
        &:nth-child(1) {
          left: 50%;
          top: 8px;
          width: 0;
        }
        &:nth-child(2) {
          transform: rotate(45deg);
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
        }
        &:nth-child(4) {
          left: 50%;
          top: 8px;
          width: 0;
        }
      }
    }
  }
  @media (max-width: 991px) {
    visibility: visible;
    display: block;
    button {
      visibility: visible;
      display: block;
    }
  }

  #mobileMenu {
    visibility: visible;
    .nav-background {
      transform: scale(1);
      border-radius: 0 0 0 0;
    }
  }
  #mainMobileMenu {
    opacity: 1;
    transform: translate(0);
    transition-duration: 0.5s;
  }
`

const MainHeader = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  border-top: 20px solid #fff;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0 0px 0px #fff;
  transition: all 0.2s linear;

  @media (max-width: 1100px) {
    border-top: 0px solid #fff !important;
    padding: 10px 0 !important;
  }
  @media (max-width: 991px) {
    z-index: 5000;
  }

  &.sticky {
    position: fixed;
  }
`

const HeaderFlex = styled.nav`
  max-width: 1360px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Logo = styled.div`
  max-width: 200px;
  width: 100%;
  transition-duration: 0.3s;
  @media (max-width: 1100px) {
    max-width: 154px;
    height: 40px;
  }
`

export default Header
