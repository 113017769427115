import React, { Component } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import PageTransition from "gatsby-plugin-page-transitions"

import Header from "../components/header"
import MobileMenu from "../components/mobile-menu"
import Footer from "../components/footer"
import HahnBanner from "../components/home-components/hahn-banner"

import "../slick.css"
import "../slick-theme.css"
import "../slick-custom.css"

import "@fontsource/work-sans"
import "@fontsource/work-sans/500.css"

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }
    this.container = null
    this.trigger = null
  }

  toggleMenu() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const children = this.props.children
    let globalStatus = "mainLayout"
    if (this.state.isOpen) {
      globalStatus += " mobileOpen"
    }
    return (
      <BannerAdjustment className="">
        <HahnBanner />
        <GlobalWrapper className={globalStatus}>
          <Header
            toggleMenu={this.toggleMenu.bind(this)}
            isOpen={this.state.isOpen}
          />
          <MobileMenu />
          {/* <Hamburger id="hamburger_icon">
            <button
              onClick={() => this.toggleMenu()}
              aria-label="Open Mobile Menu"
            >
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </button>
          </Hamburger> */}
          <PageTransition transitionTime={1000}>
            <main>{children}</main>
          </PageTransition>
          <Footer />
        </GlobalWrapper>
      </BannerAdjustment>
    )
  }
}

const BannerAdjustment = styled.div``

const Hamburger = styled.div`
  visibility: hidden;
  display: none;
  position: absolute;
  top: 22px;
  right: 20px;
  z-index: 5001;
  width: 30px;
  button {
    width: 30px;
    height: 19px;
    position: relative;
    background: transparent;
    border: none;
    outline: 0;
    padding: 0;
    visibility: hidden;
    display: none;
    &:hover {
      cursor: pointer;
    }
    > div {
      display: block;
      position: absolute;
      height: 2px;
      width: 30px;
      left: 0;
      background-color: #db4140;
      opacity: 1;
      transform: rotate(0deg) translate(0);
      transition: all 0.15s ease-in-out;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 8px;
      }
      &:nth-child(3) {
        top: 8px;
      }
      &:nth-child(4) {
        top: 16px;
      }
    }
  }
  @media (max-width: 991px) {
    visibility: visible;
    display: block;
    button {
      visibility: visible;
      display: block;
    }
  }
`

const GlobalWrapper = styled.div`
  background-color: #fff;
  position: relative;
  h1 {
    font-family: "Nobel", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  main {
    padding-top: 120px;
  }
  &.mobileOpen {
    ${Hamburger} {
      button {
        > div {
          &:nth-child(1) {
            left: 50%;
            top: 8px;
            width: 0;
          }
          &:nth-child(2) {
            transform: rotate(45deg);
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
          }
          &:nth-child(4) {
            left: 50%;
            top: 8px;
            width: 0;
          }
        }
      }
    }
    #mobileMenu {
      visibility: visible;
      .nav-background {
        transform: scale(1);
        border-radius: 0 0 0 0;
      }
    }
    #mainMobileMenu {
      opacity: 1;
      transform: translate(0);
      transition-duration: 0.5s;
    }
  }
  @media (max-width: 1100px) {
    main {
      padding-top: 75px;
    }
  }
  @media (max-width: 767px) {
    main {
      padding-top: 60px;
    }
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
