import React, { Component } from "react"
import { Link } from "gatsby"

import styled from 'styled-components'

class MainMenu extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            aboutOpen: false
        };
    }

    toggleAboutMenu() {
        this.setState({ aboutOpen: !this.state.aboutOpen });
    }

    render() {
        let aboutMenu = "aboutMenu";
        if (this.state.aboutOpen) {
          aboutMenu += ' subMenuOpen';
        }
        return (
        <MainMenuFull id={"mainMenu"}
        data-sal="slide-down"
        data-sal-duration="1000"
        data-sal-easing="ease"
        data-sal-delay="300"
        >
            <li><Link to="/services/">Services</Link></li>
            <li><Link to="/work/">Work</Link></li>
            <SubMenuMain 
                className={aboutMenu} 
                onMouseEnter={() => this.toggleAboutMenu()}
                onMouseLeave={() => this.toggleAboutMenu()}
            >
                <Link to="/about/">About</Link>
                {/* <ul class="subMenu">
                    <li><Link to="/about/our-story/">Our Story</Link></li>
                    <li><Link to="/about/meet-the-team/">Meet the Team</Link></li>
                    <li><Link to="/about/careers/">Careers</Link></li>
                </ul> */}
            </SubMenuMain> 
            <li><Link to="/lab/">Lab</Link></li>
            <li><Link to="/contact/">Contact</Link></li>
        </MainMenuFull>
        );
    }
}

const MainMenuFull = styled.ul`
    list-style: none;
    display: flex;
    align-items: flex-end;
    margin: 0;
    > li {
        font-family: "Work Sans";
        font-size: 16px;
        font-weight: bold;
        margin: 0;
        margin-left: 40px;
        margin-bottom: 8px;
        letter-spacing: 2px;
        position: relative;
        > a {
            position: relative;
            display: block;
            color: #000;
            text-decoration: none;
            text-transform: uppercase;
            padding: 5px 10px;
            transition: all .3s cubic-bezier(.77,0,.175,1);
            &:after {
                z-index: 0;
                position: absolute;
                display: block;
                content: "";
                left: 0;
                bottom: 0;
                height: 5px;
                width: 100%;
                background: #db4140;
                transform: scaleX(0);
                transform-origin: 0 100% 0;
                transition: all .3s cubic-bezier(.77,0,.175,1);
            }
        }
        &:hover {
            > a {
                transform: translateY(-2px); 
                &:after {
                    transform: scale(1);
                }
            }
        }
        &:last-child {
            font-family: din-condensed, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 30px;
            margin-bottom: 0;
            a {
                color: #db4140;
            }
        }
    }
    @media(max-width:991px) {
        display: none;
        visibility: hidden;
    }
`

const SubMenuMain = styled.li`
    ul.subMenu {
        position: absolute;
        list-style: none;
        padding: 10px;
        margin: 0;
        display: block;
        top: 150%;
        left: 0;
        width: 240px;
        border: none;
        background-color: #fff;
        border-radius: 0;
        box-shadow: 0 3px 6px rgb(0 0 0 / 15%), 0 18px 20px -10px rgb(0 0 0 / 20%);
        font-size: inherit;
        transition: all .15s ease-in-out;
        opacity: 0;
        visibility: hidden;
        z-index: 2;
        > li {
            font-family: "Work Sans";
            font-size: 16px;
            font-weight: 400;
            > a {
                position: relative;
                display: block;
                color: #000;
                text-decoration: none;
                transition: all .3s cubic-bezier(.77,0,.175,1);
                &:before {
                    position: absolute;
                    display: block;
                    content: "";
                    width: 0;
                    height: 2px;
                    top: 50%;
                    left: 0;
                    background: #362063;
                    transition: all .3s cubic-bezier(.77,0,.175,1);
                }
                &:hover {
                    padding-left: 25px;
                    color: #db4140;
                    &:before {
                        width: 20px;
                    }
                }
            }
        }
    }
    &.subMenuOpen {
        ul.subMenu {
            top: 100%;
            opacity: 1;
            visibility: visible;
        }
    }
`

export default MainMenu