import React, { Component } from "react"
import { Link } from "gatsby"
import styled from 'styled-components'

import { FaChevronDown } from 'react-icons/fa'

class MobileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            aboutOpen: false,
            twoOpen: false 
        };
    }
    
    toggleAboutMenu() {
        this.setState({ aboutOpen: !this.state.aboutOpen });
    }

    render() {
        let aboutMenu = "aboutMenu";
        if (this.state.aboutOpen) {
          aboutMenu += ' subMenuOpen';
        }
        return (
            <MobileMenuFull id={"mobileMenu"}>      
                <div class="nav-background"></div>
                <MainMobileMenu id={"mainMobileMenu"}>
                    <ul>
                        <li><Link to="/services/"><span>Services</span></Link></li>
                        <li><Link to="/work/"><span>Work</span></Link></li>
                        <li className={aboutMenu}>
                            <Link to="/about/"><span>About</span></Link>
                            {/* <button onClick={() => this.toggleAboutMenu()} ><FaChevronDown size={18}/></button>
                            <ul class="subMenu">
                                <li><Link to="/about/our-story/">Our Story</Link></li>
                                <li><Link to="/about/meet-the-team/">Meet the Team</Link></li>
                                <li><Link to="/about/careers/">Careers</Link></li>
                            </ul> */}
                        </li>
                        <li><Link to="/lab/"><span>Lab</span></Link></li>
                        <li><Link to="/contact/"><span>Contact</span></Link></li>
                    </ul>
                </MainMobileMenu>
            </MobileMenuFull>
        );
    }
}


const MobileMenuFull = styled.div`
    @media(min-width:991px) {
        visibility: hidden !important;
        display: none !important;
    }
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all .5s cubic-bezier(.77,0,.175,1);
    visibility: hidden;
    z-index: 1004;
    padding: 30px;
    padding-top: 90px;
    .nav-background {
        z-index: 0;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: scaleX(0);
        transform-origin: 0 0 0;
        transition: all .5s cubic-bezier(.77,0,.175,1);
        background: #db4140;
        border-radius: 0 100% 100% 0;
    }
`

const MainMobileMenu = styled.nav`
    position: relative;
    width: 100%;
    height: 100%;
    border: 5px solid #fff;
    counter-increment: menu-item;
    opacity: 0;
    transform: translate(-100px);
    transition: all 0s cubic-bezier(.25,.46,.45,.94);
    transition-delay: 0s;
    > ul {
        list-style: none;
        margin: 0;
        margin-top: 60px;
        > li {
            font-family: "Work Sans";
            font-size: 32px;
            font-weight: bold;
            padding: 0 20px;
            text-align: center;
            > a {
                color: #fff;
                text-decoration: none;
            }
        }
        .subMenu {
            list-style: none;
            margin: 0;
            padding: 0;
            opacity: 0;
            visibility: hidden;
            height: auto;
            max-height: 0;
            overflow: hidden;
            transition: all 0s cubic-bezier(.25,.46,.45,.94);
            transition-duration: .5s;
            li {
                font-family: "Work Sans";
                font-size: 18px;
                font-weight: 400;
                letter-spacing: 1px;
                padding: 0 20px;
                text-align: center;
                > a {
                    color: #fff;
                    text-decoration: none;
                }
            }
        }
        .aboutMenu {
            button {
                position: absolute;
                margin-left: 10px;
                background-color: transparent;
                border: none;
                outline: 0;
                color: #fff;
                margin-top: 5px;
                transition: all 0s cubic-bezier(.25,.46,.45,.94);
                transition-duration: .5s;
                &:hover {
                    cursor: pointer;
                }
            }
            &.subMenuOpen {
                button {
                    transform: rotate(-180deg);
                }
                .subMenu {
                    opacity: 1;
                    visibility: visible;
                    padding: 10px 0;
                    max-height: 200px;
                }
            }
        }
    }
`

export default MobileMenu