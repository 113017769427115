import React, { useRef, useEffect, useState } from "react"
import styled from "styled-components"
import logo from "../../images/hahn-icon.png"
import { useLocation } from "@reach/router"

export default function HahnBanner() {
  const [isHomePage, setIsHomePage] = useState(true)
  const location = useLocation()
  const hahnBanner = useRef(null)

  const listenToScroll = () => {
    if (!isHomePage) return

    const windowScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    if (windowScroll >= 150) {
      if (hahnBanner.current !== null) {
        hahnBanner.current.style.display = "none"
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll)
    return () => window.removeEventListener("scroll", listenToScroll)
  }, [])

  useEffect(() => {
    if (location.pathname !== "/") {
      setIsHomePage(false)
    }
  }, [])

  return (
    <Banner>
      {isHomePage && (
        <div class="hahn-banner" ref={hahnBanner}>
          <div class="container container--sm">
            <div class="hahn-banner__content">
              <div class="hahn-banner__logo">
                <img src={logo} alt="Hahn Logo" />
              </div>
              <div class="hahn-banner__text-group">
                <div class="hahn-banner__primary-text">
                  Hahn, White Lion’s parent agency, has acquired Statistical
                  Vision.
                </div>
                <div class="hahn-banner__secondary-text">
                  Our new agency combines predictive data with powerful design
                  to make the complex, irresistibly compelling.
                </div>
              </div>
              <div class="hahn-banner__button">
                <a href="https://www.stat-vision.com/">Learn More</a>
              </div>
            </div>
          </div>
        </div>
      )}
    </Banner>
  )
}

const Banner = styled.div`
  .hahn-banner {
    height: auto;
    font-family: "Work Sans";
    background-color: #c44c4a;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 768px) {
      height: 100px;
    }

    @media (min-width: 992px) {
      height: 150px;
    }
  }

  .hahn-banner__content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    height: 100%;
    flex-direction: column;

    @media (min-width: 768px) {
      padding: 12px 0;
      flex-direction: row;
    }
  }

  .hahn-banner__text-group {
    color: #ffffff;
    max-width: 470px;
    margin-right: 10px;
    text-align: center;
    margin-bottom: 5px;

    @media (min-width: 768px) {
      margin-right: 20px;
      text-align: left;
      margin-bottom: 0;
    }

    @media (min-width: 992px) {
      margin-right: 60px;
    }
  }

  .hahn-banner__logo {
    position: relative;

    img {
      width: 30px;
      margin-right: 10px;
      top: 3px;

      @media (min-width: 768px) {
        top: 0;
        width: 41px;
        margin-right: 37px;
      }

      @media (min-width: 992px) {
        width: 63px;
      }
    }
  }

  .hahn-banner__primary-text {
    font-size: 14px;
    line-height: 20px;

    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 24px;
    }

    @media (min-width: 992px) {
      font-size: 24px;
      line-height: 29px;
    }
  }

  .hahn-banner__secondary-text {
    font-size: 12px;
    line-height: 18px;

    @media (min-width: 768px) {
      display: block;
      font-size: 14px;
      line-height: 20px;
    }

    @media (min-width: 992px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .hahn-banner__button {
    a {
      font-size: 12px;
      line-height: 18px;
      padding: 5px;
      background-color: #ffffff;
      color: #34342f;
      text-decoration: none;

      @media (min-width: 768px) {
        font-size: 14px;
        padding: 10px;
        min-width: 70px;
      }

      @media (min-width: 992px) {
        font-size: 16px;
        padding: 10px 30px;
      }

      &:hover {
        background-color: #ffffff;
        color: #34342f;
      }
    }
  }
`
